<template>
  <div class="box">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>我的购物车</span>
        <el-steps align-center class="stepsSctive" :active="active">
          <el-step description="我的购物车" icon="el-icon-s-help"></el-step>
          <el-step description="填写核对订单" icon="el-icon-s-help"></el-step>
          <el-step description="交易成功" icon="el-icon-s-help"></el-step>
        </el-steps>
      </div>
      <el-table v-loading="loading" :cell-style="cellStyle" :data="shoppingCartList" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" :selectable="row => row.productStatus" width="55" align="center">
        </el-table-column>
        <el-table-column width="101" align="center">
          <template width="100" slot-scope="scope">
            <div class="listImg">
              <img class="table-img" :src="scope.row.filePath" />
              <div class="banSelect" v-if="!scope.row.productStatus">
                <span>该商品已下架</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商品" width="375" align="left"></el-table-column>
        <el-table-column prop="price" label="价格" align="center" width="138"></el-table-column>
        <el-table-column label="数量" align="center" width="160">
          <template slot-scope="scope">
            <el-input-number v-if="scope.row.productStatus" :min="1" :max="scope.row.storageCount || 99"
              @change="onChange(scope)" size="mini" v-model="scope.row.count" label="描述文字"></el-input-number>
            <span v-else>{{ scope.row.count }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="perPrice" label="小计" width="200" align="center"></el-table-column>
        <el-table-column label="操作" width="130" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="text" style="color: #999999;" @click="handleDelete(scope.$index, scope.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="table-bottom">
        <p style="line-height: 50px;">
          <span class="select-del" @click="clickSelectGoods">删除选中商品</span>
          <span>已选择
            <span style="color: #C91C25;">{{ totalNumber }}</span>
            件商品
          </span>
          <span style="margin-left: 29px;">
            总费用：
          </span>
          <span class="money-font">
            ￥{{ totalPrice }}
          </span>
          <el-button @click="clickBuyNow" class="button-buy">立即购买</el-button>
        </p>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "shoppingCar",
  data() {
    return {
      active: 1,
      editIsDialog: false, //编辑弹窗
      addIsDialog: false, //添加弹窗
      multipleSelection: [], //存放已选择的数据
      multipleSelection2: [],
      formData: {
        name: "",
        price: "",
        number: "",
      },
      editData: {},
      formLabelWidth: "200",
      //表格中的数据
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    cellStyle(row) {
      if (row.column.label === "小计") {
        return "color:#C91C25;font-weight:600";
      }
    },
    onChange(scope) {
      this.$store.dispatch("shoppingCar/updateShoppingCartCount", {
        Count: scope.row.count,
        Id: scope.row.id,
      });
      scope.row.perPrice = (scope.row.count * 100 * scope.row.price) / 100;
    },
    // 点击发生的变化
    handleSelectionChange(val) {
      this.multipleSelection = val; //给定义的数组赋值
    },
    confirm(list, isbatch) {
      let selectIds = list.map((item) => item.id);
      this.$confirm(
        `确认要删除${isbatch ? "这些" : "该"}宝贝吗？`,
        "删除宝贝",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$store.dispatch("shoppingCar/deleteShoppingCart", {
          Ids: list.map((item) => item.id),
        }).then(() => {
					this.$store.dispatch("getShoppingCartCount");
				})
        this.$store.commit("shoppingCar/SET_SHIPCARLIST", {
          getShoppingCartVms: this.shoppingCartList.filter((item) => {
            return !selectIds.includes(item.id);
          }),
        });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    },
    handleDelete(index, row) {
      this.confirm([row]);
    },
    clickBuyNow() {
      this.multipleSelection.length <= 0 &&
        this.$message({
          type: "warning",
          message: "请勾选商品",
        });
      this.multipleSelection.length > 0 &&
        this.$router.push({
          path: "/fillInTheOrder/fillInTheOrder",
          query: {
            shopList: JSON.stringify(
              this.multipleSelection.map((item) => {
                return {
                  productId: item.productId,
                  count: item.count,
                };
              })
            ),
          },
        });
    },
    fetch() {
      this.$store.dispatch("shoppingCar/getShoppingCartList");
    },
    clickSelectGoods() {
      this.multipleSelection.length <= 0 &&
        this.$confirm("请选中商品", "提示信息", {
          type: "info",
          showCancelButton: false,
          showConfirmButton: false,
        }).catch(() => {});
      this.multipleSelection.length > 0 &&
        this.confirm(this.multipleSelection, true);
    },
  },
  // 通过computed计算属性及时改变
  computed: {
    ...mapState({
      shoppingCartList: (state) => {
        return state.shoppingCar.shoppingCartList;
      },
      loading: (state) => {
        return state.loading;
      },
    }),
    // 总价
    totalPrice() {
      var price_total = 0;
      this.multipleSelection.forEach((item) => {
        return (price_total += item.price * item.count);
      });
      return price_total.toFixed(2);
    },
    // 总数
    totalNumber() {
      var number_total = 0;
      this.multipleSelection.map((item) => {
        return (number_total += item.count);
      });

      return number_total;
    },
  },
};
</script>

<style lang="less" scoped>
.clearfix {
  padding: 0 0 20px 0; /* no */
  font-size: 18px; /* no */
}
.box-card {
  width: 1200px; /* no */
  margin: 20px auto; /* no */
}
/deep/.el-input-number__decrease {
  background: white;
}
.table-img {
  width: 80px; /* no */
  height: 80px; /* no */
  border: none;
}
.table-bottom {
  border: solid 1px #eeeeee; /* no */
  padding-left: 20px; /* no */
  height: 50px; /* no */
  margin-top: 20px; /* no */
}
.select-del {
  margin-right: 670px;
  cursor: pointer;
}
.money-font {
  color: #c91c25;
  margin-right: 21px; /* no */
  width: 100px; /* no */
  font-weight: 600;
}
.button-buy {
  background-color: #c91c25;
  color: white;
  width: 150px; /* no */
  height: 50px; /* no */
  float: right;
}
.stepsSctive {
  width: 370px; /* no */
  float: right;
  /deep/.is-finish {
    color: #2ec4cd;
  }

  /deep/.is-process {
    color: rgb(192 196 204);
  }

  /deep/.el-step__description {
    padding-top: 6px; /* no */
  }
}

/deep/.el-input-number__increase {
  background: white;
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #c91c25;
  border-color: #c91c25;
}

/deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #c91c25;
  border-color: #c91c25;
}

.listImg {
  position: relative;

  .banSelect {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.7);
    line-height: 85.3px; /* no */
    text-align: center;

    span {
      transform: rotate(45deg);
      display: inline-block;
    }
  }
}
</style>
